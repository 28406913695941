//
// user.js
// Use this to write your custom JS
//
$(window).on('ajaxInvalidField', function (event, fieldElement, fieldName, errorMsg, isFirst) {
    $(fieldElement).addClass('border-danger');
});

// Disable the stupid alert() popup with the message
$(window).on('ajaxErrorMessage', function (event, message) {
    event.preventDefault();
});

$(document).on('ajaxPromise', '[data-request]', function () {
    $(this).closest('form').find('.has-error').removeClass('has-error');
});

$(document).on('ajaxAlways', function () {
    $('.dismiss-modal').on('click', function () {
        $('.modal.show').modal('hide');
    });
});

$('.text-navbar').each(function (o) {
    let txt = $(this).text().trim();

    if (txt != '') {
        $(this).closest('.navbar-topbar').removeClass('d-none');
    }
});

$(function () {
    $('.dismiss-modal').on('click', function () {
        $('.modal.show').modal('hide');
    });

    let modalCart = $('#modal-cart');
    let cartTriggerButton = $('.cart-trigger-btn');
    let modalProductAdded = $('#modal-product-added');

    $.subscribe('smart-shop.cart.productAdded', function (e, data) {
        modalProductAdded.modal('show');
        cartTriggerButton.find('span').attr('data-cart-items', data.new_items_quantity);
    });

    $.subscribe('smart-shop.cart.update', function (e, data) {
        cartTriggerButton.find('span').attr('data-cart-items', data.new_items_quantity);
    });

    cartTriggerButton.click(function (e) {
        e.preventDefault();
        $('.modal.show').modal('hide');

        $.request('onRenderCart', {
            loading: $.oc.stripeLoadIndicator,
            success: function (data) {
                this.success(data).done(function () {
                    modalCart.modal('show');
                });
            }
        })
    });
});

window.initFilters = function (component) {
    let $body = $('body');
    // let lastChoice;
    let $productList = $('.product-list');
    // let $propertiesForm = $('.smart-products-filter');

    $body.on('change', '.js-smart-filter select', function () {
        $('.smart-products-filter').trigger('submit');
    });

    $body.on('change', '.js-smart-filter input', function () {
        // lastChoice = $(this).attr('name');
        $('.smart-products-filter').trigger('submit');
        // $propertiesForm.trigger('submit');
    });

    $body.on('change', '.js-smart-select-filter', function () {
        // lastChoice = $(this).attr('name');
        console.log($(this).attr('name'));
        $('.smart-products-filter').trigger('submit');
        // $propertiesForm.trigger('submit');
    });

    $body.on('click', '.js-smart-clear-filter', function () {
        var $parent = $(this).closest('.smart-property');

        if ($parent.length < 1) {
            $parent = $(this).closest('.smart-property-group');
        }

        $parent.find(':input:not([type="checkbox"])').val('');
        $parent.find('input[type="checkbox"]').prop('checked', false);
        $parent.find('.smart-filter__option--selected').removeClass('smart-filter__option--selected')

        var slider = $parent.find('.smart-slider-handles')[0]
        if (slider) {
            slider.noUiSlider.updateOptions({
                start: [slider.dataset.min, slider.dataset.max]
            });
        }

        $('.smart-products-filter').trigger('submit');
        // $propertiesForm.trigger('submit');
    });

    $body.on('click', '.products-filter-btn', function () {
        $('.products-filter').show();
    });

    $body.on('click', '.products-filter-close-btn', function () {
        $('.products-filter').hide();
    });

    $body.on('submit', '.smart-products-filter', function (e) {

        e.preventDefault();

        $.publish('smart-shop.products.load.start');

        $productList.addClass('loading');
        console.log('add class');

        let sorter = $('#smart-sorter').val();

        $(this).request('productsFilter::onSetFilter', {
            data: {sort: sorter},
            loading: $.oc.stripeLoadIndicator,
            complete: function (response) {
                if (response.responseJSON.hasOwnProperty('queryString')) {
                    history.replaceState(null, '', '?' + response.responseJSON.queryString)
                }

                $('[data-filter]').hide();

                if (response.responseJSON.hasOwnProperty('filter')) {
                    for (var filter of Object.keys(response.responseJSON.filter)) {
                        $('[data-filter="' + filter + '"]').show();
                    }
                }

                $.publish('smart-shop.products.load.complete');
            },
            error: function () {
                $.oc.stripeLoadIndicator.hide()
                $.oc.flashMsg({text: 'Helaas, filter kan niet worden toegepast', class: 'error'})
                $.publish('smart-shop.products.load.error')
            }
        });
    });

    $.subscribe('smart-shop.products.run.complete', function () {
        $.request('productsFilter::onRefreshFilters', {
            complete: function () {
                $.oc.stripeLoadIndicator.hide();
                $productList.removeClass('loading');
            }
        });
    });

    $('.smart-slider-handles').each(function () {
        var el = this;
        noUiSlider.create(el, {
            start: [el.dataset.start, el.dataset.end],
            connect: true,
            range: {
                min: [parseFloat(el.dataset.min)],
                max: [parseFloat(el.dataset.max)]
            },
            pips: {
                mode: 'range',
                density: 20
            }
        }).on('change', function (values) {
            $('[data-min="' + el.dataset.target + '"]').val(values[0])
            $('[data-max="' + el.dataset.target + '"]').val(values[1])
            $propertiesForm.trigger('submit');
        });
    });
};
